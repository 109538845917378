<template>
  <v-app>
<!--    <HeaderNav/>-->
    <v-main>
      <router-view/>
    </v-main>

    <FooterNav/>
  </v-app>
</template>

<script>
// import HeaderNav from '@/components/HeaderNav.vue';
import FooterNav from '@/components/FooterNav.vue';

export default {
  components: {
    FooterNav,
  },
};
</script>

<style lang="scss">
@import 'assets/scss/main.scss';

</style>
