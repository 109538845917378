<template>
  <div>
    <div class="footer">
      <template v-if="isDesktop">
        <div class="container" style="max-width: 1470px">
          <v-row>
            <v-col style="padding-right: 0">
              <h5 style="font-weight: bold">О КОМПАНИИ</h5>
              <template v-for="(val, idx) in headerLinks" :key="idx">
                <h5 @click="routeTo" style="cursor: pointer; margin-bottom: 0">{{val.title}}</h5>
              </template>
            </v-col>
            <v-col style="padding-right: 0; padding-left: 0">
              <h5 style="font-weight: bold">ИЩИТЕ НАС</h5>
              <h5 class="h5"><a href="mailto:dialcon.krg@mail.ru"><v-icon class="one" color="white">mdi-at</v-icon> dialcon.krg@mail.ru</a></h5>
              <h5 class="h5" @click="redirectToInstagram" style="cursor:pointer;"><v-icon class="one" color="white"
              >mdi-instagram</v-icon> mirnagrad</h5>
            </v-col>
            <v-col style="padding-right: 0; padding-left: 0">
              <h5 style="font-weight: bold">НАШ АДРЕС</h5>
              <h5 style="margin-bottom: 0"><a href="https://2gis.kz/karaganda/search/%D0%9A%D0%B0%D0%B7%D0%B0%D1%85%D1%81%D1%82%D0%B0%D0%BD%2C%20%D0%B3.%20%D0%9A%D0%B0%D1%80%D0%B0%D0%B3%D0%B0%D0%BD%D0%B4%D0%B0%2C%20%D1%83%D0%BB.%20%D0%9C%D0%BE%D0%BB%D0%BE%D0%BA%D0%BE%D0%B2%D0%B0%20102/geo/11822584677020447/73.092943%2C49.779654?m=73.092913%2C49.779671%2F17.9">Казахстан,</a></h5>
              <h5 style="margin-bottom: 0"><a href="https://2gis.kz/karaganda/search/%D0%9A%D0%B0%D0%B7%D0%B0%D1%85%D1%81%D1%82%D0%B0%D0%BD%2C%20%D0%B3.%20%D0%9A%D0%B0%D1%80%D0%B0%D0%B3%D0%B0%D0%BD%D0%B4%D0%B0%2C%20%D1%83%D0%BB.%20%D0%9C%D0%BE%D0%BB%D0%BE%D0%BA%D0%BE%D0%B2%D0%B0%20102/geo/11822584677020447/73.092943%2C49.779654?m=73.092913%2C49.779671%2F17.9">г. Караганда, ул. Молокова 102</a></h5>
            </v-col>
            <v-col  style="padding-left: 0">
              <h5 style="font-weight: bold">ТЕЛЕФОН ДЛЯ ЗАКАЗОВ:</h5>
              <h5 style="margin-bottom: 0"><a href="https://wa.me/77781179910">+7 (778) 117-99-10</a></h5>
              <h5 style="margin-bottom: 0"><a href="https://wa.me/77001118034">+7 (700) 111-80-34</a></h5>
              <h5 style="margin-bottom: 0"><a href="https://wa.me/77071118033">+7 (707) 111-80-33</a></h5>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-else>
        <div class="container" style="max-width: 1470px">
          <v-row>
            <v-col cols="6">
              <v-col>
                <h5 style="font-weight: bold">О компании</h5>
                <template v-for="(val, idx) in headerLinks" :key="idx">
                  <h5 @click="routeTo" style="cursor: pointer; margin-bottom: 0">{{val.title}}</h5>
                </template>
              </v-col>
              <v-col>
                <h5 style="font-weight: bold">НАШ АДРЕС</h5>
                <h5 style="margin-bottom: 0"><a href="https://2gis.kz/karaganda/search/%D0%9A%D0%B0%D0%B7%D0%B0%D1%85%D1%81%D1%82%D0%B0%D0%BD%2C%20%D0%B3.%20%D0%9A%D0%B0%D1%80%D0%B0%D0%B3%D0%B0%D0%BD%D0%B4%D0%B0%2C%20%D1%83%D0%BB.%20%D0%9C%D0%BE%D0%BB%D0%BE%D0%BA%D0%BE%D0%B2%D0%B0%20102/geo/11822584677020447/73.092943%2C49.779654?m=73.092913%2C49.779671%2F17.9">Казахстан,</a></h5>
                <h5 style="margin-bottom: 0"><a href="https://2gis.kz/karaganda/search/%D0%9A%D0%B0%D0%B7%D0%B0%D1%85%D1%81%D1%82%D0%B0%D0%BD%2C%20%D0%B3.%20%D0%9A%D0%B0%D1%80%D0%B0%D0%B3%D0%B0%D0%BD%D0%B4%D0%B0%2C%20%D1%83%D0%BB.%20%D0%9C%D0%BE%D0%BB%D0%BE%D0%BA%D0%BE%D0%B2%D0%B0%20102/geo/11822584677020447/73.092943%2C49.779654?m=73.092913%2C49.779671%2F17.9">г. Караганда, ул. Молокова 102</a></h5>
              </v-col>
            </v-col>
            <v-col cols="6" style="padding: 12px 0 0 0">
              <v-col>
                <h5 style="font-weight: bold">ИЩИТЕ НАС</h5>
                <h5 class="h5"><a href="mailto:dialcon.krg@mail.ru"><v-icon class="one1" color="white">mdi-at</v-icon>dialcon.krg@mail.ru</a></h5>
                <h5 class="h5" @click="redirectToInstagram" style="cursor:pointer;"><v-icon class="one2" color="white"
                >mdi-instagram</v-icon> mirnagrad</h5>
              </v-col>
              <br>
              <v-col>
                <h5 style="font-weight: bold">ТЕЛЕФОН ДЛЯ ЗАКАЗОВ:</h5>
                <h5 style="margin-bottom: 0"><a href="https://wa.me/77781179910">+7 (778) 117-99-10</a></h5>
                <h5 style="margin-bottom: 0"><a href="https://wa.me/77001118034">+7 (700) 111-80-34</a></h5>
                <h5 style="margin-bottom: 0"><a href="https://wa.me/77071118033">+7 (707) 111-80-33</a></h5>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: "FooterNav",
  data() {
    return {
      isDesktop: window.innerWidth >= 1300,
    }
  },
  computed: {
    headerLinks() {
      return  [
        { title: 'О нас', scroll: 'about' },
        { title: 'Почему нас выбирают?', scroll: 'why_us' },
        { title: 'Нам доверяют', scroll: 'trusted_by' },
        { title: 'Доставка', scroll: 'delivery' },
      ]
    },
  },
  methods: {
    redirectToInstagram() {
      window.location.href = 'https://www.instagram.com/mirnagrad?igsh=bHFhenU3ZWhkcDFu'
    },
    routeTo() {
      const home = window.location.pathname
      if (home !== '/') {
        window.location.href = '/'
      }
    },
  }
}
</script>

<style scoped>
.footer {
  background-color: #012677;
  padding-top: 30px;
  padding-right: 273px !important;
  padding-left: 320px !important;
  padding-bottom: 50px;
}
.one1, .one2 {
  font-size: 30px;
}
a {
  text-decoration: none;
  color: white;
}
@media screen and (min-width: 300px) and (max-width: 400px) {
  .footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  h4, h5 {
    font-size: 17px;
  }
  .h5 {
    font-size: 16px;
  }
  .one1, .one2 {
    font-size: 16px;
  }
}
@media screen and (max-width: 667px) {
  .footer {
    padding-left: 0 !important;
    padding-right: 5px !important;
  }
  h4, h5 {
    font-size: 17px;
  }
  .h5 {
    font-size: 16px;
  }
  .one {
    font-size: 16px;
  }
}

@media screen and (min-width: 667px) and (max-width: 1500px) {
  .footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  h4, h5 {
    font-size: 17px;
  }
  .h5 {
    font-size: 16px;
  }
  .one {
    font-size: 16px;
  }
}


h5 {
  color: white;
}

</style>
